@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;600;700;800;900&display=swap');

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 17, 2023 */



@font-face {
    font-family: 'gotham_roundedbold';
    src: url('gothamrounded-bold-webfont.woff2') format('woff2'),
         url('gothamrounded-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'gotham_roundedbold_italic';
    src: url('gothamrounded-bolditalic-webfont.woff2') format('woff2'),
         url('gothamrounded-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'gotham_roundedbook';
    src: url('gothamrounded-book-webfont.woff2') format('woff2'),
         url('gothamrounded-book-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'gotham_roundedbook_italic';
    src: url('gothamrounded-bookitalic-webfont.woff2') format('woff2'),
         url('gothamrounded-bookitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'gotham_roundedlight';
    src: url('gothamrounded-light-webfont.woff2') format('woff2'),
         url('gothamrounded-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'gotham_roundedlight_italic';
    src: url('gothamrounded-lightitalic-webfont.woff2') format('woff2'),
         url('gothamrounded-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'gotham_roundedmedium';
    src: url('gothamrounded-medium-webfont.woff2') format('woff2'),
         url('gothamrounded-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'gotham_roundedmedium_italic';
    src: url('gothamrounded-mediumitalic-webfont.woff2') format('woff2'),
         url('gothamrounded-mediumitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


h1, h2 {
	font-family: gotham_roundedbold, sans-serif;
	font-weight: 800; 
    font-style: normal; 
}
